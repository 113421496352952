import GenericField from 'modules/shared/fields/genericField';
import * as yup from 'yup';
import { i18n } from 'i18n';
export default class StringField extends GenericField {
  constructor(
    name,
    label,
    {
      required = false,
      min = undefined,
      max = undefined,
      matches = undefined,
      password = false,
      confirmPassword = false,
    } = {},
  ) {
    super(name, label);

    this.required = required;
    this.matches = matches;
    this.min = min;
    this.max = max;
    this.password = password;
    this.confirmPassword = confirmPassword;
  }

  forTable(overrides) {
    const defaultRender = undefined;

    const {
      title = this.label,
      sorter = true,
      dataIndex = this.name,
      render = defaultRender,
    } = overrides || {};

    return {
      title,
      sorter,
      dataIndex,
      render,
    };
  }

  forView(value) {
    return value;
  }

  forFormInitialValue(value) {
    return value;
  }

  forForm() {
    let yupChain = yup
      .string()
      .nullable(true)
      .trim()
      .label(this.label);

    if (this.required) {
      yupChain = yupChain.required();
    }

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min);
    }

    if (this.max) {
      yupChain = yupChain.max(this.max);
    }

    if (this.matches) {
      yupChain = yupChain.matches(/^[0-9]/);
    }

    if (this.password) {
      yupChain = yupChain.test(
        'no-sequential-or-repeated',
        i18n('validation.string.password'),
        (value) => {
          if (!value) return true;

          const lower = value.toLowerCase();

          // Verifica se todos os caracteres são iguais (ex: "aaaaaa" ou "111111")
          const allSame = lower.split('').every((char) => char === lower[0]);
          if (allSame) return false;

          // Verifica se é uma sequência ascendente ou descendente.
          let ascending = true;
          let descending = true;

          for (let i = 1; i < lower.length; i++) {
            const diff = lower.charCodeAt(i) - lower.charCodeAt(i - 1);
            if (diff !== 1) ascending = false;
            if (diff !== -1) descending = false;
          }

          if (ascending || descending) return false;
          return true;
        }
      )
    }

    if (this.confirmPassword) {
      yupChain = yupChain.oneOf(
        [yup.ref('password'), null],
        i18n('validation.string.passwordsDontMatch'),
      );
    }

    return yupChain;
  }

  forFilter() {
    let yupChain = yup
      .string()
      .nullable(true)
      .trim()
      .label(this.label);

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min);
    }

    if (this.max) {
      yupChain = yupChain.max(this.max);
    }

    if (this.matches) {
      yupChain = yupChain.matches(/^[0-9]/);
    }

    return yupChain;
  }

  forExport() {
    return yup.mixed().label(this.label);
  }

  forImport() {
    let yupChain = yup
      .string()
      .nullable(true)
      .trim()
      .label(this.label);

    if (this.required) {
      yupChain = yupChain.required();
    }

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min);
    }

    if (this.max) {
      yupChain = yupChain.max(this.max);
    }

    if (this.matches) {
      yupChain = yupChain.matches(/^[0-9]/);
    }

    return yupChain;
  }
}
